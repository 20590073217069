<template>
    <header>
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-5 py-4">
            <div class="container">
                <h1 class="navbar-brand fs-1">ToDo List 
                    <i class="fa-solid fa-check-double ms-3"></i>
                </h1>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'ProjectName',
    components: {
        ProjectName() {
            
        }
    }
}
</script>
<template>
   <span></span>
</template>




<script>
export default {
    name: 'DeletedTask',
    components: {
        DeletedTask() {

        }
    }
}
</script>




<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>